exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-form-index-tsx": () => import("./../../../src/pages/contact/form/index.tsx" /* webpackChunkName: "component---src-pages-contact-form-index-tsx" */),
  "component---src-pages-contact-thanks-index-tsx": () => import("./../../../src/pages/contact/thanks/index.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-services-ds-1001-index-tsx": () => import("./../../../src/pages/services/DS-1001/index.tsx" /* webpackChunkName: "component---src-pages-services-ds-1001-index-tsx" */),
  "component---src-pages-services-en-1001-index-tsx": () => import("./../../../src/pages/services/EN-1001/index.tsx" /* webpackChunkName: "component---src-pages-services-en-1001-index-tsx" */),
  "component---src-pages-services-en-1002-index-tsx": () => import("./../../../src/pages/services/EN-1002/index.tsx" /* webpackChunkName: "component---src-pages-services-en-1002-index-tsx" */),
  "component---src-pages-services-en-1003-index-tsx": () => import("./../../../src/pages/services/EN-1003/index.tsx" /* webpackChunkName: "component---src-pages-services-en-1003-index-tsx" */),
  "component---src-pages-services-ga-1001-index-tsx": () => import("./../../../src/pages/services/GA-1001/index.tsx" /* webpackChunkName: "component---src-pages-services-ga-1001-index-tsx" */),
  "component---src-pages-services-ga-1003-index-tsx": () => import("./../../../src/pages/services/GA-1003/index.tsx" /* webpackChunkName: "component---src-pages-services-ga-1003-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-terms-dscc-index-tsx": () => import("./../../../src/pages/terms/dscc/index.tsx" /* webpackChunkName: "component---src-pages-terms-dscc-index-tsx" */),
  "component---src-pages-terms-ga-4-conf-index-tsx": () => import("./../../../src/pages/terms/ga4conf/index.tsx" /* webpackChunkName: "component---src-pages-terms-ga-4-conf-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-tools-ip-filter-index-tsx": () => import("./../../../src/pages/tools/ip-filter/index.tsx" /* webpackChunkName: "component---src-pages-tools-ip-filter-index-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-item-tsx": () => import("./../../../src/templates/blog-item.tsx" /* webpackChunkName: "component---src-templates-blog-item-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/news-item.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-news-list-tsx": () => import("./../../../src/templates/news-list.tsx" /* webpackChunkName: "component---src-templates-news-list-tsx" */)
}

